import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import {isBoxDemo} from "../../../utils/inventoryRequests";
import useNotification from "../../../hooks/notification";
import useMutation from "../../../hooks/useMutation";
import {DELETE_INVENTORY_ITEM_MUTATION} from "../../../constants/graphql/mutations";

function synchronous(array, fn) {
  const results = [];
  return array.reduce(function(p, item) {
    return p.then(function() {
      return fn(item).then(function(data) {
        results.push(data);
        return results;
      });
    });
  }, Promise.resolve());
}

export default function VaStatus({ disabled, inventoryRequest, changeStatus, reFetch }) {
  const [loading, setLoading] = useState(false);
  const { notifySuccess } = useNotification();
  const [deleteInventoryItem] = useMutation(DELETE_INVENTORY_ITEM_MUTATION);

  const handleChangeStatus = status => value => {
    const checked = value.target.checked;
    if (reFetch && status === "canceled" && value.target.checked === true) {
      setLoading(true);
      return synchronous(
        inventoryRequest.inventoryItems.map(v => v.id),
        id => {
          return deleteInventoryItem({
            variables: { id },
          });
        }
      ).then(() => {
        value.target.checked = checked;
        return changeStatus(status)(value).then(() => {
          return reFetch().then(() => {
            setLoading(false);
            notifySuccess("Order successfully cancelled");
          });
        });
      });
    }
    return changeStatus(status)(value);
  };

  return isBoxDemo ? (
    <Box maxWidth={300} display="flex" flexDirection="column">
      <FormControlLabel
        style={{ margin: 0, marginBottom: 4 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.confirmation}
            onChange={changeStatus("confirmation")}
          />
        }
        label="Confirmed by Vendor"
      />
      <FormControlLabel
        style={{ margin: 0, marginBottom: 4 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.scheduled}
            onChange={changeStatus("scheduled")}
          />
        }
        label="Scheduled by Vendor"
      />
      <FormControlLabel
        style={{ margin: 0, marginBottom: 4 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.completed}
            onChange={changeStatus("completed")}
          />
        }
        label="Completed"
      />
      <FormControlLabel
        style={{ margin: 0 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.canceled}
            onChange={handleChangeStatus("canceled")}
          />
        }
        label="Cancelled"
      />
    </Box>
  ) : (
    <Box maxWidth={300} display="flex" flexDirection="column">
      <FormControlLabel
        style={{ margin: 0, marginBottom: 4 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.scheduled}
            onChange={changeStatus("scheduled")}
          />
        }
        label="Scheduled"
      />
      <FormControlLabel
        style={{ margin: 0, marginBottom: 4 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.picked}
            onChange={changeStatus("picked")}
          />
        }
        label="Picked"
      />
      <FormControlLabel
        style={{ margin: 0, marginBottom: 4 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 0, paddingRight: 8 }}
            checked={inventoryRequest?.vaStatus?.completed}
            onChange={changeStatus("completed")}
          />
        }
        label="Completed"
      />
      <Box display="flex">
        <FormControlLabel
          style={{ margin: 0 }}
          control={
            loading ? (
              <Box pr={1.4}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Checkbox
                disabled={disabled}
                style={{ padding: 0, paddingRight: 8 }}
                checked={inventoryRequest?.vaStatus?.canceled}
                onChange={handleChangeStatus("canceled")}
              />
            )
          }
          label="Cancelled"
        />
      </Box>
    </Box>
  );
}
