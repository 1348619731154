import React from "react";
import Box from "@mui/material/Box";
import ActionModal from "../../../components/modals/action-modal";
import NewLocationForm from "./NewLocationForm";

export default function NewLocationModal({
  open,
  onClose,
  clientId,
  triggerScan,
  setTriggerScan,
  code,
  rackLocations,
  onSuccess,
}) {
  return (
    <ActionModal
      open={open}
      title="Select New Storage Location"
      maxWidth={640}
      onClose={onClose}
      disableTransition
    >
      <Box mt={1} />
      <NewLocationForm
        clientId={clientId}
        triggerScan={triggerScan}
        setTriggerScan={setTriggerScan}
        onClose={onClose}
        onSuccess={onSuccess}
        code={code}
        rackLocations={rackLocations}
      />
    </ActionModal>
  );
}
