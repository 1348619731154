import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ActionModal from "../../../components/modals/action-modal";
import ScanditScanner from "src/components/barcode/ScanditScanner/ScanditScanner";
import ScannedAssetDetails from "./ScannedAssetDetails";
import NewLocationModal from "./NewLocationModal";

import MoveLocationsImage from "../../../../assets/images/move_locations.png";

import useAuth from "src/hooks/useAuth";

const MoveLocationsModal = ({ open, onClose }) => {
  const { user } = useAuth();
  const [openScan, setOpenScan] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [openNewLocationModal, setOpenNewLocationModal] = useState(false);

  const [triggerScan, setTriggerScan] = useState(false);

  const [code, setCode] = useState(null);
  const [rackLocations, setRackLocations] = useState([]);

  const handleAssetDetectedCode = (code) => {
    setScannedBarcode(code);
    handleToggleScan();
  };

  const handleToggleScan = () => {
    setOpenScan((v) => !v);
  };

  const handleScanNewLocation = () => {
    setTriggerScan(true);
    setOpenNewLocationModal(true);
  };

  const handleTransactionSuccess = () => {
    setOpenNewLocationModal(false);
    onClose();
    setScannedBarcode(null);
    setCode(null);
    setRackLocations([]);
    setClientId(null);
  };

  return (
    <>
      <ActionModal
        open={open}
        maxWidth={640}
        title={!scannedBarcode ? "Inventory Move" : ""}
        onClose={onClose}
        disableTransition
      >
        {!scannedBarcode && (
          <Box mt={1} display="flex" alignItems="center">
            <Box mr={3} flex="0 1 300px">
              <img
                src={MoveLocationsImage}
                alt="Example"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>

            <Box display="flex" flexDirection="column" height="100%">
              <Typography variant="body1" gutterBottom>
                Scan the barcode on your item label to move it to a new location.
              </Typography>
              <Box mt={5} alignSelf="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToggleScan}
                  size="large"
                >
                  Scan Barcode
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {openScan && (
          <ScanditScanner
            barcodeTrigger={user.company.settings.barcode_trigger}
            onDetected={scannedBarcode ? null : handleAssetDetectedCode}
            onClose={handleToggleScan}
          />
        )}

        {scannedBarcode && (
          <>
            <ScannedAssetDetails
              scannedBarcode={scannedBarcode}
              onClientIdExtracted={(clientId) => {
                // Extracted clientId for WAREHOUSE_LOCATIONS_BY_CLIENT
                setClientId(clientId);
              }}
              onAssetData={(assetData) => {
                // Extracted asset data (assetId + rackLocations) for CREATE_INVENTORY_TRANSACTION_MUTATION
                setCode(assetData.code);
                setRackLocations(assetData.rackLocations);
              }}
            />
            <Box mt={5} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleScanNewLocation}
                size="large"
              >
                Scan New Location
              </Button>
            </Box>
          </>
        )}
      </ActionModal>

      {openNewLocationModal && (
        <NewLocationModal
          open={openNewLocationModal}
          clientId={clientId}
          triggerScan={triggerScan}
          setTriggerScan={setTriggerScan}
          onClose={() => {
            setOpenNewLocationModal(false);
            setTriggerScan(false);
          }}
          onSuccess={handleTransactionSuccess}
          code={code}
          rackLocations={rackLocations.map((location) => ({
            ...location,
            rack_location: [],
          }))}
        />
      )}
    </>
  );
};

export default MoveLocationsModal;
